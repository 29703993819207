<template>
    <div>
        <b-modal ref="modal" hide-footer size="xl">
            <template v-slot:modal-title>
				{{ $t("action.importer") }}
			</template>
            <b-form-textarea
            v-if="!importModal"
            id="textarea"
            v-model="text"
            placeholder="Enter something..."
            rows="10"></b-form-textarea>
            <b-button v-if="!importModal" variant="primary" @click="handleData()">{{$t('tiers.tiers_import.tiers_importer')}}</b-button>
            <Import v-if="importModal" :form.sync="form" :numberOfSelect.sync="numberOfSelect" :options="options" @clicked="importData($event)"/>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Accounting from "@/mixins/Accounting.js"
    import AccountingAccount from "@/mixins/AccountingAccount.js"
    import Tools from "@/mixins/Tools.js"

export default {
        name: "AccountingAccountImport",
        props: ['accountingplan_id'],
        mixins: [Navigation, Accounting, AccountingAccount, Tools],
        data () {
            return {
                importModal: false,
                options: [
                    { value: '0', text: this.getTrad('table.accounting_account.accountingaccount_label'), column: 'accountingaccount_label', requis: true },
                    { value: '1', text: this.getTrad('table.accounting_account.accountingaccount_number'), column: 'accountingaccount_number', requis: true},
                    { value: 'null', text: this.getTrad('global.ne_pas_importer'), column: 'null', requis: false},
                ],
                numberOfSelect: 2,
                form: [],
                text: "",
                events_tab: {
                    'TableAction::goToImportAccountingAccount': () => {
                        this.showModal()
                    },
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                //
            },
            async showModal() {
                this.$refs['modal'].show()
                this.text = ""
                this.importModal = false
            },
            async handleData() {
                if(this.text == "" || this.text == null){
                    return
                }
                const wordByspace = this.text.split('\n')
                for (const [i, elements] of wordByspace.entries()) {
                    const words = elements.split('\t')
                    let obj = {}
                    this.numberOfSelect = 0
                    obj.checked = true
                    for (const [j, word] of words.entries()) {
                        this.numberOfSelect++
                        obj[j] = word
                    }
                    this.$set(this.form, i, obj)
                }
                this.importModal = true
            },
            async importData(event){
                for (const elements of event) {
                    let result = null
                    result = await this.addAccountingAccount(
                        this.accountingplan_id,
                        null, //accountingaccount_vat
                        elements['accountingaccount_label'],
                        elements['accountingaccount_number'],
                        false, //accountingaccount_debours
                        false //accountingaccount_avf
                    )

                    if(result) {
                        this.$parent.loadAccountingAccountListe()
                        this.$refs['modal'].hide()
                        this.$emit('submit')
                    } else {
                        this.form_message = "error.LEP"
                    }
                }
                this.$refs['modal'].hide()
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            Import: () => import('@/components/Utils/Import'),
        }
    }
</script>
